<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content" style="padding: 0 10px;">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="auto"
                    label-suffix=":"
            >
                <el-form-item label="配置标识" prop="configCode">
                    <el-input
                            v-model="detailForm.configCode"
                            placeholder="请输入配置标识"
                    />
                </el-form-item>
                <el-form-item label="配置值" prop="configValue">
                    <el-input
                            v-model="detailForm.configValue"
                            placeholder="请输入配置值"
                            type="textarea"
                            :rows="6"
                            size="mini"
                    />
                </el-form-item>
                <el-form-item label="配置描述" prop="remark">
                    <el-input
                            v-model="detailForm.remark"
                            placeholder="请输入配置描述"
                            type="textarea"
                            :rows="6"
                            size="mini"
                    />
                </el-form-item>

                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save">保 存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        getResConfigDetail,addResConfig,editResConfig
    } from "@/api/business";


    export default {
        data() {
            return {
                pageLoad: false,
                title: "新增系统资源配置",
                id: null,
                detailForm: {
                    id: this.$route.query.id || null,
                    configCode: "",
                    configValue: "",
                    remark: "",
                },
                notLimited: false,
                limitDisable: false,
                detailRules: {
                    configCode: [{required: true, message: "请输入配置标识"}],
                    configValue: [{required: true, message: "请输入配置值"}],
                    remark: [{required: true, message: "请输入配置描述"}],
                },
                saveLoad: false,
            };
        },
        created() {
            if (this.$route.query.id) {
                this.title = '编辑系统资源配置';
                this.getResConfigDetail();
            }
        },
        methods: {
            //新增系统资源
            addResConfig(form) {
                this.saveLoad = true;
                addResConfig(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('添加成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //编辑系统资源
            editResConfig(form) {
                this.saveLoad = true;
                editResConfig(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getResConfigDetail() {
                this.pageLoad = true;
                getResConfigDetail({
                    id: this.detailForm.id
                }).then((res) => {
                    Object.assign(this.detailForm, res.data);
                    this.pageLoad = false;
                }).catch(() => {
                    this.pageLoad = false;
                });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        if (this.detailForm.id) {
                            this.editResConfig(this.detailForm)
                        } else {
                            this.addResConfig(this.detailForm)
                        }
                    }
                });
            },
            cancel() {
                this.$router.push("/systemResource");
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
